@import url("https://fonts.googleapis.com/css?family=Satisfy");

body {
  overflow: auto;
  overflow-x: hidden !important;
  font-family: "Lato", sans-serif;
}
.slick-dots li.slick-active button:before {
  color: rgba(255, 195, 40, 1) !important;
  padding-right: unset !important;
}
.slick-dots {
  bottom: 18px !important;
}
.slick-dots li {
  margin: 0px !important;
}

.carousal-container {
  height: 500px !important;
  width: 400px !important;
}
.carousal-card {
  height: 500px;
  width: -webkit-fill-available !important;
}

.agree-checkbox {
  font-size: 14px;
}
.secure {
  font-size: 10px;
  color: gray;
}
.slick-prev {
  left: 35px !important;
}
.slick-next {
  right: 35px !important;
}
.slick-next,
.slick-prev {
  background: rgba(172, 255, 47, 0);
  z-index: 1;
}
.slick-prev:before,
.slick-next:before {
  opacity: 0;
  line-height: unset !important;
}
.slick-prev:before,
.slick-next:before {
  color: rgba(255, 194, 40, 0.457) !important;
}

a {
  color: white !important;
  text-decoration: none !important;
}

.carousel-indicators [data-bs-target] {
  background-color: rgba(255, 195, 40, 1) !important;
  border-radius: 50% !important;
  height: 6px !important;
  width: 6px !important;
}
.header-container {
  padding: 4px 0px;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: rgb(254, 254, 254);
  border-bottom: 1.5px solid rgba(128, 128, 128, 0.196);
}

.header {
  color: #fff;
  width: -webkit-fill-available;
  height: 50px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
}
/* Customize the scrollbar */
body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: rgba(255, 195, 40, 1);
  border-radius: 6px;
}
p {
  font-size: 13px !important;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.178);
}
.carousal-card {
  position: relative;
}

.topbar-container {
  background-color: rgba(65, 65, 65, 1);
  height: 40px;
  width: -webkit-fill-available;
}

.topbar-flex {
  color: white;
}

.mail-flex {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.passion-text {
  font-size: 16px;
  font-weight: 600;
}
.contact-form-error {
  color: red;
  font-size: 10px;
  text-align: start !important;
  height: 0;
  font-weight: 600;
}
.submit-btn {
  transition: transform 0.2s ease;
}

.submit-btn:hover {
  transform: scale(1.02);
}

@media screen and (min-width: 768px) {
  .topbar-flex {
    display: flex;
    height: 40px;
    justify-content: flex-end;
    align-items: center;
    font-size: 13px;
    gap: 30px;
  }
  .carousal-card img {
    height: 400px;
    width: 400px;
  }

  .alpha-sub-heading {
    color: rgba(157, 19, 0, 1);
    text-align: start;
    margin: 20px 0px;
  }

  .contact-us-btn {
    height: 37px;
    width: 100px;
    background-color: rgba(255, 195, 40, 1);
    color: rgba(0, 0, 0, 1);
    border: 1px solid rgba(255, 195, 40, 1);
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
  }
  .contact-us-two-btn {
    background-color: rgba(255, 195, 40, 1);
    color: rgba(0, 0, 0, 1);
    border: 1px solid rgba(255, 195, 40, 1);
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
    width: 400px;
    margin-top: 30px;
  }

  .carousel-captions {
    transform: translate(-50%, -50%);
    text-align: center;
    width: 40% !important;
  }
  .carousel-caption {
    left: 50% !important;
    right: 0rem !important;
  }
  .carousel-caption p {
    text-align: center !important;
  }

  /* About Page */
  .founder-details {
    margin-top: 10px;
  }
  .founder-details .name {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }

  .founder-details .position {
    font-size: 0.9em;
    font-weight: 600;
    text-align: center;
  }
  .about-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 80px;
  }
  .about-heading {
    text-align: start;
    margin-bottom: 40px;
  }
  .about-heading-mobile {
    display: none;
  }
  .sub-heading-about {
    margin: 14px 0px;
    color: rgba(255, 195, 40, 1);
    text-align: start;
    font-size: 15px;
  }
  .about-content {
    width: 44%;
  }
  .about-content p {
    text-align: start;
  }
  .parliament-img {
    height: 500px;
    width: 50%;
  }
  .image-container {
    position: relative;
    display: inline-block;
  }

  .dual-image {
    width: 100%;
    position: relative;
  }
  .dual-image-mobile {
    display: none;
  }
  .hammer {
    top: 140px;
  }

  .hammer-flex {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 14px;
  }
  .dual-image-container {
    height: 100%;
  }
  .hammer-flex-screen-one {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 50%;
    padding-top: 10%;
    padding-bottom: 10%;
    padding-right: 80px;
  }
  .hammer-flex-screen-two {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 50%;
    padding-bottom: 10%;
    padding-right: 80px;
    position: relative;
    top: 80px;
  }
  .hammer-flex-screen-one .content,
  .hammer-flex-screen-two .content {
    width: 40%;
  }
  .satisfy-font-text {
    font-family: "Satisfy" !important;
    font-size: 50px;
  }

  .alpha-sub-heading-2 {
    text-align: start;
    margin: 20px 0px;
  }
  .alpha-sub-heading p {
    text-align: start;
  }
  .alpha-quotes {
    text-align: start;
  }
  /* Services Styles */

  .services-container {
    padding: 50px 80px;
  }
  .services-container h1 {
    text-align: center !important;
  }
  .services-container .text-center {
    width: 50%;
    color: rgba(102, 102, 102, 1);
  }
  .services-text {
    font-size: 12px;
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cards-container {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }
  .cards {
    padding: 20px 30px;
    height: 300px;
    width: 48%;
    background-color: rgba(255, 243, 214, 1);
    position: relative;
    margin: auto;
  }
  .cards h3 {
    text-align: start;
  }
  .card-text {
    text-align: start;
    margin-top: 40px;
    width: 50%;
  }
  .card-image {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  /* Contact Styles */
  .contact-container {
    background-color: rgba(243, 243, 243, 1);
    padding: 50px 80px;
  }
  .slick-dots li button:before {
    color: rgba(255, 195, 40, 1) !important;
  }
  .slick-dots li button:before:hover {
    color: unset !important;
  }
  .contact-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .contact-flex h2 {
    text-align: start;
  }
  input {
    border: 1px solid rgba(231, 234, 238, 1);
    width: 400px;
    height: 40px;
    outline: none !important;
    box-shadow: none !important;
    margin-top: 6px !important;
    text-align: unset !important;
    padding-left: 30px;
    padding-right: 10px;
  }
  textarea {
    resize: none !important;
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid rgba(231, 234, 238, 1);
    width: 400px;
    margin-top: 6px !important;
    height: 80px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  input[type="checkbox"] {
    height: 15px;
    width: 15px;
    accent-color: rgba(255, 195, 40, 1);
    cursor: pointer;
    position: relative;
    bottom: 2px;
    border: 1px solid rgba(231, 234, 238, 1) !important;
  }
  .checkbox {
    height: 20px;
    width: 20px;
  }
  .contact-form-label {
    text-align: start;
    font-weight: 450;
    margin-top: 40px;
  }

  /* Footer Styles */
  .footer-values:hover {
    color: rgba(255, 195, 40, 1);
  }
  .mail-flex:hover {
    color: rgba(255, 195, 40, 1);
  }
  .footer-container {
    background-color: rgba(0, 0, 0, 1);
    height: auto;

    color: rgba(153, 153, 153, 1);
  }
  .footer-container a {
    color: rgba(153, 153, 153, 1) !important ;
  }
  .footer-inside-container {
    padding: 60px 80px;
    display: flex;
    gap: 164px;
  }
  .first-footer-part {
    width: 40%;
  }
  .footer-logo {
    text-align: start;
    display: flex;
  }
  .footer-text {
    margin-top: 30px;
    text-align: start;
    font-size: 12px !important;
  }
  .footer-services-part {
    width: 300px;
  }
  .heading {
    color: rgba(255, 255, 255, 1);
    margin-bottom: 30px;
    margin-top: 40px;
    text-align: start;
    font-size: 12px !important;
  }
  .footer-values {
    font-size: 12px !important;
    text-align: start;
    margin-top: 12px;
    cursor: pointer;
  }
  .footer-values-address {
    font-size: 12px !important;
    text-align: start;
    margin-top: 4px;
  }
  .address-footer-start {
    font-size: 12px !important;
    text-align: start;
    margin-top: 12px;
  }

  .border-line {
    width: -webkit-fill-available;
    border-bottom: 1px solid rgba(221, 221, 221, 0.587);
  }
  .copy-rights-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }
  .copy-rights {
    font-size: 12px;
  }
  /* History Styles */
  .history-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 80px;
  }
  .__web-inspector-hide-shortcut__ {
    visibility: unset !important;
  }
  .topRightFiller {
    position: absolute;
    right: 0;
  }
  .bottomLeftFiller {
    position: relative;
    top: 293px;
    right: 148px;
  }
  .history-container h1,
  p {
    text-align: start;
  }
  /* .first-history-flex {
    width: 50%;
  } */
  .left-comma {
    display: flex;
    justify-content: flex-start;
  }
  .right-comma {
    display: flex;
    justify-content: flex-end;
  }

  .quote-width {
    /* width: 70%; */
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
  .first-history-flex h2{
text-align: center;
  }
  .history-text-container{
    font-size: 12px;
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .history-text {
    color: rgba(102, 102, 102, 1);
    width: 50%;
    text-align: center;
  }
  .hammer-relative {
    position: relative;
    z-index: 20;
    background-image: url('../assets/dualImage.png');
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
  }
  .mail-footer{
    font-size: 12px;
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
  }
}
@media screen and (max-width: 767px) {
  .dual-image {
    display: none;
  }
  .parliament-img {
    width: -webkit-fill-available;
  }
  .get-in-touch-img {
    width: -webkit-fill-available;
  }
  .carousal-card img {
    height: 500px;
    width: 100%;
    padding:10px ;
  }
  .about-flex {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px 30px;
  }
  .about-heading {
    text-align: center;
  }
  .sub-heading-about {
    color: rgba(255, 195, 40, 1);
    text-align: start;
    font-size: 15px;
    margin-top: 30px;
  }
  .dual-image-container {
    height: 100%;
  }
  .about-content p {
    text-align: justify;
  }
  .dual-image-mobile {
    width: -webkit-fill-available;
    padding: 0px !important;
  }
  .hammer {
    top: 140px;
  }
  .hammer-flex {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 14px;
  }
  .hammer-relative {
    position: relative;
    z-index: 20;
    background-image: url('../assets/dualImage-mobile.png');
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 950px;
  }
  .hammer-flex-screen-one {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 50%;
    padding-top: 20%;
    padding-bottom: 10%;
    padding-right: 30px;
    padding-left: 30px;
  }
  .hammer-flex-screen-two {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 50%;
    padding-bottom: 20%;
    padding-right: 30px;
    padding-left: 30px;
    position: relative;
    top: 80px;
  }
  .satisfy-font-text {
    font-family: "Satisfy" !important;
    font-size: 24px;
  }
  .alpha-sub-heading {
    color: rgba(157, 19, 0, 1);
    text-align: start;
    margin: 10px 0px;
    font-size: 15px;

  }

  .alpha-sub-heading-2 {
    text-align: start;
    margin: 10px 0px;
    font-size: 15px;
  }
  .hammer-flex-screen-two,
  .hammer-flex-screen-one p {
    margin-top: -6px !important;
    text-align: justify;
  }
  .about-heading-mobile {
    margin-top: 30px;
    text-align: center;
  }
  .about-heading {
    display: none;
  }
  .contact-container {
    padding: 30px;
    background-color: rgba(243, 243, 243, 1);
  }
  .contact-flex {
    display: flex;
    flex-direction: column-reverse;
  }
  .get-in-touch {
    text-align: center !important;
    margin-top: 30px;
  }
  input {
    border: 1px solid rgba(231, 234, 238, 1);
    width: -webkit-fill-available;
    height: 40px;
    outline: none !important;
    box-shadow: none !important;
    margin-top: 6px !important;
    text-align: unset !important;
    padding-left: 30px;
    padding-right: 10px;
  }
  textarea {
    resize: none !important;
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid rgba(231, 234, 238, 1);
    width: -webkit-fill-available;
    margin-top: 6px !important;
    height: 80px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .contact-us-two-btn {
    background-color: rgba(255, 195, 40, 1);
    color: rgba(0, 0, 0, 1);
    border: 1px solid rgba(255, 195, 40, 1);
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
    width: -webkit-fill-available;
    margin-top: 20px;
  }
  .contact-form-label {
    text-align: start;
    font-weight: 450;
    margin-top: 10px;
  }
  .footer-inside-container {
    background-color: rgba(0, 0, 0, 1);
    color: rgba(153, 153, 153, 1);
    padding: 30px;
  }
  .first-footer-part-mobile {
    display: flex !important;
    justify-content: center !important;
  }
  .footer-text {
    margin-top: 30px;
    text-align: center !important;
  }
  .footer-services-part {
    margin-top: 30px;
  }
  .heading {
    color: rgba(255, 255, 255, 1);
    margin-bottom: 30px;
    margin-top: 40px;
    font-size: 16px !important;
    text-align: center;
  }
  .footer-values {
    font-size: 12px !important;
    text-align: start;
    margin-top: 12px;
    cursor: pointer;
    text-align: center !important;
    color: rgba(153, 153, 153, 1);
  }
  .footer-values-address {
    font-size: 12px !important;
    text-align: center;
    margin-top: 4px;
  }
  .address-footer-start {
    font-size: 12px !important;
    text-align: center;
    margin-top: 12px;
  }
  .border-line {
    width: -webkit-fill-available;
    border-bottom: 1px solid rgba(221, 221, 221, 0.587);
    padding: unset !important;
  }
  .mail-footer{
    font-size: 12px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .copy-rights-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background-color: rgba(0, 0, 0, 1);
    color: rgba(153, 153, 153, 1);
    padding: 30px;
  }
  .copy-rights {
    font-size: 12px;
  }
  .services-container {
    padding: 30px;
  }
  .services-container h1 {
    text-align: center !important;
  }
  .cards {
    padding: 20px 10px;
    /* height: 260px; */
    background-color: rgba(255, 243, 214, 1);
    margin: auto;
    margin-top: 30px;
  }
  .cards h3 {
    text-align: start;
  }
  .card-text {
    text-align: start;
    margin-top: 20px;
    font-size: 12px;
  }

  .services-container .text-center {
    color: rgba(102, 102, 102, 1);
  }
  .card-image-mobile-flex {
    display: flex;
    flex-direction: column-reverse;
  }
  .footer-values:hover {
    color: rgba(255, 195, 40, 1);
  }
  .mail-flex:hover {
    color: rgba(255, 195, 40, 1);
  }
  .history-container {
    padding: 30px;
    display: flex;
    flex-direction: column-reverse;
    margin-top: -80px !important;
  }
  .name {
    font-size: 16px;
  }

  .topbar-flex {
    color: white;
    font-size: 12px;
  }
  .topbar-flex {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
  }
  .carousal-container {
    width: unset !important;
  }
  .history-heading-mobile {
    text-align: center;
    margin-top: 50px !important;
  }
  .founder-details {
    margin-top: -50px !important;
  }
  .founder-details .name {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }

  .founder-details .position {
    font-size: 0.8em;
    font-weight: 600;
    text-align: center;
  }
  .carousel-item img {
    width: auto; /* Set maximum width to 100% */
    height: 300px; /* Maintain aspect ratio */
    object-fit: contain;
  }
  .text-center {
    font-size: 13px;
    text-align: justify;
  }
  .card-text {
    text-align: justify;
  }
  .history-text {
    text-align: justify !important;
    font-size: 12px;
    margin-top: 30px;
  }
  .quote-width {
    margin-top: 30px;
  }
  .left-comma{
    text-align: start;
  }
  .right-comma{
    text-align: end;
  }
  .passion-text{
    text-align: center;
    font-size: 14px;
  }
  .contact-form-label{
    margin-top: 30px;
  }
 
}

@media screen and (min-width: 1500px) {
.res-screen {
  display: flex !important;
  justify-content: center;
  width: 100%;
}
.screen-render {
  width: 1500px !important;
}
}

